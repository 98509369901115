import axios from '@/axios'
import { store } from '@/store/index'
import { DefaultInput, FormErrors, ModalForm, NumberInput } from 'apptimizm-ui'
import { PropType, defineComponent, ref } from 'vue'
import { FormFields, FormFieldsMeta } from './types'

export default defineComponent({
  props: {
    close: {
      type: Function as PropType<() => void>,
      required: true
    },
    selectedAreas: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  setup (props) {
    const form = ref(new FormFields())
    const formMeta = new FormFieldsMeta()
    const formErrors = ref(new FormErrors({}))

    const submit = async () => {
      const params = ref<{
        'project_id': string,
        'storage_name': string,
        'start_serial_number'?: number,
        'end_serial_number'?: number,
        'zones_ids'?: string[]
      }>({
        project_id: store.currentProject.id,
        storage_name: form.value.storageName
      })

      if (form.value.startSerialNumber && form.value.endSerialNumber) {
        params.value.start_serial_number = form.value.startSerialNumber
        params.value.end_serial_number = form.value.endSerialNumber

        await axios.patch(formMeta.endpointUpdate, params.value)
      } else {
        params.value.zones_ids = props.selectedAreas

        await axios.patch(formMeta.endpointUpdateSelectedAreas, params.value)
      }
      form.value.reset()
      props.close()
    }

    const modalForm = () => (
      <ModalForm
        cancel={props.close}
        confirm={() => {}}
        header='Назначить склад'
        slots={{
          default: () => (
            <>
              { !props.selectedAreas.length
                ? <div class="row cg-10 mt-4">
                  <label>
                    <NumberInput
                      class={`short input-placeholder-up ${!form.value.startSerialNumber ? 'hide-zero' : ''}`}
                      placeholder="Зоны с..."
                      modelValue={form.value.startSerialNumber}
                      onValueChange={(v: number) => { form.value.startSerialNumber = parseInt(String(v)) } }
                      errors={formErrors.value.forField('startSerialNumber')}
                    />
                  </label>
                  <label>
                    <NumberInput
                      class={`short input-placeholder-up ${!form.value.endSerialNumber ? 'hide-zero' : ''}`}
                      placeholder="по..."
                      modelValue={form.value.endSerialNumber}
                      onValueChange={(v: number) => { form.value.endSerialNumber = parseInt(String(v)) } }
                      errors={formErrors.value.forField('amount')}
                    />
                  </label>
                </div>
                : null
              }
              <div class='mt-2'>
                <label>
                  <DefaultInput
                    class={form.value.storageName ? 'input-placeholder-up' : ''}
                    placeholder="Код склада"
                    modelValue={form.value.storageName}
                    onValueChange={(v: string) => { form.value.storageName = v } }
                    errors={formErrors.value.forField('storageName')}
                  />
                </label>
              </div>
            </>
          ),
          buttons: () =>
            <button
              onClick={submit}
              class="primary-button mt-3">Назначить
            </button>
        }}
      />
    )
    return () => (
      <div>{modalForm()}</div>
    )
  }
})