import { computed, defineComponent, PropType, ref } from 'vue'
import { FormErrors, ModalForm, NumberInput } from 'apptimizm-ui'
import { FormFields, FormFieldsMeta } from './types'
import axios from '@/axios'
import { store } from '@/store'

export interface IZoneBulkDeleteRequestData {
  project: string
  zones?: string[]
  /* eslint-disable camelcase */
  start_serial_number?: number
  end_serial_number?: number
  /* eslint-enable */
}

export default defineComponent({
  props: {
    close: {
      type: Function as PropType<() => void>,
      required: true
    },
    selectedAreas: {
      type: Object as PropType<string[]>,
      required: true
    }
  },
  setup (props) {
    const form = ref(new FormFields())
    const formMeta = new FormFieldsMeta()
    const formErrors = ref(new FormErrors({}))

    const isSelectedZones = computed(() => !!props.selectedAreas.length)

    async function submit () {
      const data: IZoneBulkDeleteRequestData = {
        project: store.currentProject.id
      }

      if (isSelectedZones.value) {
        data.zones = props.selectedAreas
      } else {
        const { startSerialNumber, endSerialNumber } = form.value
        data.start_serial_number = startSerialNumber
        data.end_serial_number = endSerialNumber
      }

      const response = (await axios.post(formMeta.endpointDelete, data)).data
      if (response?.errors) formErrors.value = formMeta.errors(response.errors)
      else {
        props.close()
      }
    }

    const modalForm = () => (
      <ModalForm
        cancel={props.close}
        confirm={() => {}}
        header='Удаление зон'
        slots={{
          default: () => (
            <>
              {isSelectedZones.value
                ? <p class='mt-4'>Вы действительно хотите удалить выбранные зоны?</p>
                : <div class='mt-4'>
                  <div class="row cg-10">
                    <label>
                      <NumberInput
                        class={`short input-placeholder-up ${!form.value.startSerialNumber ? 'hide-zero' : ''}`}
                        placeholder="Зоны с..."
                        modelValue={form.value.startSerialNumber}
                        onValueChange={(v: number) => { form.value.startSerialNumber = parseInt(String(v)) } }
                        errors={formErrors.value.forField('startSerialNumber')}
                      />
                    </label>
                    <label>
                      <NumberInput
                        class={`short input-placeholder-up ${!form.value.endSerialNumber ? 'hide-zero' : ''}`}
                        placeholder="по..."
                        modelValue={form.value.endSerialNumber}
                        onValueChange={(v: number) => { form.value.endSerialNumber = parseInt(String(v)) } }
                        errors={formErrors.value.forField('endSerialNumber')}
                      />
                    </label>
                  </div>
                </div>
              }
            </>
          ),
          buttons: () =>
            <button
              onClick={submit}
              class="primary-button mt-3">Удалить
            </button>
        }}
      />
    )
    return () => (
      <div>{modalForm()}</div>
    )
  }
})