import { defineComponent, PropType, ref } from 'vue'
import { DefaultInput, FormErrors, ModalForm, NumberInput } from 'apptimizm-ui'
import { FormFields, FormFieldsMeta } from './types'
import axios from '@/axios'

export default defineComponent({
  props: {
    close: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup (props) {
    const form = ref(new FormFields())
    const formMeta = new FormFieldsMeta()
    const formErrors = ref(new FormErrors({}))
    const submit = async () => {
      if (form.value.storageName === '') form.value.storageName = 'нет'
      const response = (await axios.post(formMeta.endpointCreate, formMeta.dump(form.value))).data
      if (response?.errors) formErrors.value = formMeta.errors(response.errors)
      else {
        props.close()
      }
    }

    const modalForm = () => (
      <ModalForm
        cancel={props.close}
        confirm={() => {}}
        header='Создание зон'
        slots={{
          default: () => (
            <>
              <div class="row cg-10 mt-4">
                <label>
                  <NumberInput
                    class={`short input-placeholder-up ${!form.value.startSerialNumber ? 'hide-zero' : ''}`}
                    placeholder="Начальный номер"
                    modelValue={form.value.startSerialNumber}
                    onValueChange={(v: number) => { form.value.startSerialNumber = parseInt(String(v)) } }
                    errors={formErrors.value.forField('startSerialNumber')}
                  />
                </label>
                <label>
                  <NumberInput
                    class={`short input-placeholder-up ${!form.value.amount ? 'hide-zero' : ''}`}
                    placeholder="Количество"
                    modelValue={form.value.amount}
                    onValueChange={(v: number) => { form.value.amount = parseInt(String(v)) } }
                    errors={formErrors.value.forField('amount')}
                  />
                </label>
              </div>
              <div class='mt-2'>
                <label>
                  <DefaultInput
                    class={form.value.storageName ? 'input-placeholder-up' : ''}
                    placeholder="Код склада"
                    modelValue={form.value.storageName}
                    onValueChange={(v: string) => { form.value.storageName = v } }
                    errors={formErrors.value.forField('storageName')}
                  />
                </label>
              </div>
            </>
          ),
          buttons: () =>
            <button
              onClick={submit}
              class="primary-button mt-3">Создать
            </button>
        }}
      />
    )
    return () => (
      <div>{modalForm()}</div>
    )
  }
})